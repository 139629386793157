$theme-color : #116F41;

body {
    font-family: Open Sans, Arial, sans-serif;
    font-size: 14px;
    color: #03324E;
    background-color: #fff;
    line-height: 1.7em;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.img-simule {
    width:300px;
}
.et_pb_fullwidth_menu_0_tb_header.et_pb_fullwidth_menu .et_mobile_menu,
.et_pb_fullwidth_menu_0_tb_header.et_pb_fullwidth_menu .et_mobile_menu ul {
    background-color: #3f6d84 !important
}
#main-header,
        #main-header .nav li ul,
        .et-search-form,
        #main-header .et_mobile_menu {
            background-color: #3f6d84
        }

        .et_pb_fullwidth_menu_0_tb_header.et_pb_fullwidth_menu .et_mobile_menu {
            border-color: #3f6d84
        }

.et_pb_section_6.et_pb_section {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(0, 0, 0, 0)!important
}

div.et_pb_section.et_pb_section_0 {
    background-image: url(../imgs/slider.jpg) !important;
}
.et_pb_menu__menu {
    li {color : $theme-color  !important}
}

.et_pb_fullwidth_menu .et_pb_row, .et_pb_menu .et_pb_row {
    min-height: 81px
}

.ajust-buttom-simule {
    margin-top: 10px;
    margin-left: 58px
}

.et_pb_section_3.et_pb_section {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(0, 0, 0, 0)!important
}

.et_pb_text_0 h1 {
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    font-size: 38px;
    color: $theme-color !important;
    line-height: 1.4em
}

#wapper-hero-simulator {
    display: flex;
    flex-direction: column;
    height: 1000px;
}

.et_pb_fullwidth_menu_0_tb_header.et_pb_fullwidth_menu ul li.current-menu-item a {
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    color: #fff  
}

.et_pb_fullwidth_menu_0_tb_header.et_pb_fullwidth_menu ul li a {
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    color: #fff   
}

#menu-home .et_pb_module.et_pb_fullwidth_menu.et_pb_fullwidth_menu_0_tb_header.et_pb_bg_layout_light.et_pb_text_align_right.et_dropdown_animation_fade.et_pb_fullwidth_menu--with-logo.et_pb_fullwidth_menu--style-left_aligned {
    padding: 6px 0;
}

.menu-home-fixed-header .et_pb_fullwidth_menu_0_tb_header.et_pb_fullwidth_menu {
    background-color: #3f6d84 !important;
    -webkit-transition: background-color 200ms ease-out;
    -moz-transition: background-color 200ms ease-out;
    -o-transition: background-color 200ms ease-out;
    transition: background-color 200ms ease-out;
}
.btn-account {
    background-color: $theme-color ;
    padding: 12px;
    font-weight: bold;


    a {
        color:  #fff !important;
    }  
}

.fixed-header-white {
    display: none !important;
    height: 50px;
    width: 174px;
}

.fixed-header-gray {
    height: 50px;
    width: 174px;
}

.menu-home-fixed-header {
    .fixed-header-white {
        display: block !important;

    }
    .fixed-header-gray {
        display: none !important;
        height: 50px;
    }
}

#wrapper-carousel {
    display: flex;
    overflow-x: scroll;
    flex-flow: nowrap;
    position: relative;
    z-index: 99;
}


.et_pb_fullwidth_menu_0_tb_header.et_pb_fullwidth_menu .et_mobile_menu a {
    color: #66ae45!important
}

.top-text {
    margin-top :75px
}

.button-simule {
    text-align: center;
}

@media only screen and (min-width:1200px) {
    .et_pb_row {max-width: 1320px;}
}

@media only screen and ( max-width: 479px ) {
    #menu-home .et_pb_module.et_pb_fullwidth_menu.et_pb_fullwidth_menu_0_tb_header.et_pb_bg_layout_light.et_pb_text_align_right.et_dropdown_animation_fade.et_pb_fullwidth_menu--with-logo.et_pb_fullwidth_menu--style-left_aligned {
        padding: 0;
    }
    
    .et-boc {
        margin-top: -15px;
    }

    .fixed-header-white {
        display: none !important;
        height: 40px;
        width: 139px;
    }

    
    .et_pb_fullwidth_menu .et_pb_row, .et_pb_menu .et_pb_row {
        min-height: 65px
    }

    .et_pb_section_3.et_pb_section {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .et_pb_section_6.et_pb_section {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #wrapper-inconta {
        padding-top: 0px;
    }

    div.et_pb_section.et_pb_section_0 {
        background-position: -270px 0px;
        background-size: 250%;
    }

    
    .top-text {
        margin-top :5px
    }

    .img-simule {
        width:225px;
    }

    .et_pb_text_0 h1 {
        font-size: 28px;
    }


    .et_pb_fullwidth_menu_0_tb_header.et_pb_fullwidth_menu .et_mobile_menu a {
        color: #fff!important
    }
    
    .ajust-buttom-simule {
        margin-left: 0px;
        margin-top: 30px;
        height: 35px;
    }

    #wapper-hero-simulator {
        flex-direction: row;
        padding: 0;
        height: 426px;
    }

    #wrapper-carousel {
        display: grid;
        overflow-x: hidden;
    }

    #wrapper-blog::before {
        display: none;
    }

    #wrapper-tirar-duvidas::before {
        top: -86%;
        left: 0;
        transform: rotate(120deg);
    }

    /*carousel information*/
    #wrapper-carousel .et_pb_column.et_pb_column_1_5 {
        max-width: 80%;
        flex: 0 0 80%;
        margin-right: 5.5%;
    }

    /*blog*/
    #wrapper-blog .et_pb_salvattore_content {
        display: grid;
        overflow-x: hidden;
        flex-flow: nowrap;
        width: 340px;
    }

    #wrapper-blog .et_pb_salvattore_content .column.size-1of4 {
        flex: 0 0 80%;
        max-width: 80%;
        margin-right: 5.5%;
    }

    #wrapper-hero {
        height: 100%;
    }
    
    #simulator {
        padding: 24px;    
    }
    
    #wrapper-carousel {
        margin-left: 9.5%;
        width: 340px;
        overflow-x: hidden;
    }
}



@media only screen and ( min-width: 426px ) and (max-width: 478px ) {
    div.et_pb_section.et_pb_section_0 {
        background-position: -270px 0px;
        background-size: 250%;
    }

    .button-simule {
        text-align: left;
    }

    .et-boc {
        margin-top: -15px;
    }

    .fixed-header-white {
        display: none !important;
        height: 40px;
        width: 139px;
    }

    .et_pb_fullwidth_menu .et_pb_row, .et_pb_menu .et_pb_row {
        min-height: 65px
    }

    #menu-home .et_pb_module.et_pb_fullwidth_menu.et_pb_fullwidth_menu_0_tb_header.et_pb_bg_layout_light.et_pb_text_align_right.et_dropdown_animation_fade.et_pb_fullwidth_menu--with-logo.et_pb_fullwidth_menu--style-left_aligned {
        padding: 0;
    }

    .et_pb_section_6.et_pb_section {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .et_pb_section_3.et_pb_section {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #wrapper-inconta {
        padding-top: 0px;
    }
    
    .top-text {
        margin-top :5px
    }

    .img-simule {
        width:225px;
    }

    .et_pb_text_0 h1 {
        font-size: 28px;
    }

     .et_pb_fullwidth_menu_0_tb_header.et_pb_fullwidth_menu .et_mobile_menu a {
        color: #fff!important
    }
    

    #wrapper-carousel {
        display: grid;
        overflow-x: hidden;
    }

    .ajust-buttom-simule {
        margin-left: 0px;
        margin-top: 30px;
        height: 35px;
    }


    #wapper-hero-simulator {
        flex-direction: row;
        padding: 0;
        height: 590px;
    }

    #wrapper-blog .et_pb_salvattore_content {
        display: grid;
        overflow-x: hidden;
        flex-flow: nowrap;
        width: 430px;
    }
    
    #wrapper-carousel {
        margin-left: 9.5%;
        width: 430px;
        overflow-x: hidden;
    }
}


@media only screen and ( min-width: 376px ) and (max-width: 425px ) {
    div.et_pb_section.et_pb_section_0 {
        background-position: -270px 0px;
        background-size: 250%;
    }

    .et-boc {
        margin-top: -15px;
    }

    .button-simule {
        text-align: left;
    }

    .fixed-header-white {
        display: none !important;
        height: 40px;
        width: 139px;
    }

    .et_pb_fullwidth_menu .et_pb_row, .et_pb_menu .et_pb_row {
        min-height: 65px
    }

    #menu-home .et_pb_module.et_pb_fullwidth_menu.et_pb_fullwidth_menu_0_tb_header.et_pb_bg_layout_light.et_pb_text_align_right.et_dropdown_animation_fade.et_pb_fullwidth_menu--with-logo.et_pb_fullwidth_menu--style-left_aligned {
        padding: 0;
    }

    .et_pb_section_6.et_pb_section {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .et_pb_section_3.et_pb_section {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #wrapper-inconta {
        padding-top: 0px;
    }
    
    .top-text {
        margin-top :5px
    }

    .img-simule {
        width:225px;
    }

    .et_pb_text_0 h1 {
        font-size: 28px;
    }

    .et_pb_fullwidth_menu_0_tb_header.et_pb_fullwidth_menu .et_mobile_menu a {
        color: #fff!important
    }
    
    #wrapper-carousel {
        display: grid;
        overflow-x: hidden;
    }

    .ajust-buttom-simule {
        margin-left: 0px;
        margin-top: 30px;
        height: 35px;
    }


    #wapper-hero-simulator {
        flex-direction: row;
        padding: 0;
        height: 590px;
    }

    #wrapper-blog .et_pb_salvattore_content {
        display: grid;
        overflow-x: hidden;
        flex-flow: nowrap;
        width: 384px;
    }
    
    #wrapper-carousel {
        margin-left: 9.5%;
        width: 384px;
        overflow-x: hidden;
    }
}

@media only screen and ( min-width: 321px ) and (max-width: 375px ) {
    div.et_pb_section.et_pb_section_0 {
        background-position: -270px 0px;
        background-size: 250%;
    }

    .button-simule {
        text-align: left;
    }

    .et-boc {
        margin-top: -15px;
    }

    .fixed-header-white {
        display: none !important;
        height: 40px;
        width: 139px;
    }

    .et_pb_fullwidth_menu .et_pb_row, .et_pb_menu .et_pb_row {
        min-height: 65px
    }

    #menu-home .et_pb_module.et_pb_fullwidth_menu.et_pb_fullwidth_menu_0_tb_header.et_pb_bg_layout_light.et_pb_text_align_right.et_dropdown_animation_fade.et_pb_fullwidth_menu--with-logo.et_pb_fullwidth_menu--style-left_aligned {
        padding: 0;
    }

    .et_pb_section_6.et_pb_section {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .et_pb_section_3.et_pb_section {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #wrapper-inconta {
        padding-top: 0px;
    }
    .top-text {
        margin-top :5px
    }

    .img-simule {
        width:225px;
    }

    .et_pb_text_0 h1 {
        font-size: 28px;
    }

 

    .et_pb_fullwidth_menu_0_tb_header.et_pb_fullwidth_menu .et_mobile_menu a {
        color: #fff!important
    }
    
    #wrapper-carousel {
        display: grid;
        overflow-x: hidden;
    }

    .ajust-buttom-simule {
        margin-left: 0px;
        margin-top: 30px;
        height: 35px;
    }


    #wapper-hero-simulator {
        flex-direction: row;
        padding: 0;
        height: 590px;
    }

    #wrapper-blog .et_pb_salvattore_content {
        display: grid;
        overflow-x: hidden;
        flex-flow: nowrap;
        width: 340px;
    }
    
    #wrapper-carousel {
        margin-left: 9.5%;
        width: 340px;
        overflow-x: hidden;
    }
    
}

@media only screen and ( min-width: 300px ) and (max-width: 320px ) {
    div.et_pb_section.et_pb_section_0 {
        background-position: -270px 0px;
        background-size: 250%;
    }

    .et-boc {
        margin-top: -15px;
    }

    .button-simule {
        text-align: left;
    }

    .fixed-header-white {
        display: none !important;
        height: 40px;
        width: 139px;
    }
    
    .et_pb_fullwidth_menu .et_pb_row, .et_pb_menu .et_pb_row {
        min-height: 65px
    }

    #menu-home .et_pb_module.et_pb_fullwidth_menu.et_pb_fullwidth_menu_0_tb_header.et_pb_bg_layout_light.et_pb_text_align_right.et_dropdown_animation_fade.et_pb_fullwidth_menu--with-logo.et_pb_fullwidth_menu--style-left_aligned {
        padding: 0;
    }

    .et_pb_section_3.et_pb_section {
        padding-top: 0px;
        padding-bottom: 0px;
    }


    .et_pb_section_6.et_pb_section {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #wrapper-inconta {
        padding-top: 0px;
    }
    .top-text {
        margin-top :5px
    }

    .img-simule {
        width:225px;
    }

    .et_pb_text_0 h1 {
        font-size: 24px;
    }


    .et_pb_fullwidth_menu_0_tb_header.et_pb_fullwidth_menu .et_mobile_menu a {
        color: #fff!important
    }
    

    #wrapper-carousel {
        display: grid;
        overflow-x: hidden;
    }

    .ajust-buttom-simule {
        margin-left: 0px;
        margin-top: 30px;
        height: 35px;
    }

    #wapper-hero-simulator {
        flex-direction: row;
        padding: 0;
        height: 590px;
    }

    #wrapper-blog .et_pb_salvattore_content {
        display: grid;
        overflow-x: hidden;
        flex-flow: nowrap;
        width: 300px;
    }
    
    #wrapper-carousel {
        margin-left: 9.5%;
        width: 300px;
        overflow-x: hidden;
    }
    
}


